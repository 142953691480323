import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  TitleStyle1, Container, HomeButton, PageHolder,
} from '../Common';
import Constants, { Title1, TextLink, TextBody } from '../Constants';
import Api, { ASSETS_SERVER } from '../../lib/Api';
import { InnerPageContext } from './L2Page';

const ContentHolder = styled.div`
display:flex;
@media (max-width:${Constants.LG}px) {
 flex-wrap:wrap;
}
 
`;

const MainContent = styled.div` 
flex:3;
font-size:18px;
margin-right:20px;
${TextBody};
color:${Constants.TEXT_COLOR};
@media (max-width:${Constants.LG}px) {
 margin-right:10px;
 flex:auto;
 max-width:100vw;
 padding:20px;
}
`;

const LeftContent = styled.div`
flex:1;
@media (max-width:${Constants.LG}px) {
  flex:auto;
  max-width:100vw;
}
`;

const MainImageSM = styled.img`
width:100%;
height:auto;
display:none;
@media (max-width:${Constants.LG}px) {
 max-width:50%;
  display:block;
  margin:auto;
}
@media (max-width:${Constants.SM}px) {
 max-width:100%;
  display:block;
}
`;

const MainImage = styled.img`
width:100%;
height:auto;
display:block;
@media (max-width:${Constants.LG}px) {
 max-width:100%;
 display:none;
}
`;

const ButtonHolder = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    margin-top:40px;
`;

const SubContent = styled.div`
margin-top:20px;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
justify-items:center;
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
 max-width:600px;
 padding-left:10px;
}

`;
const SubContentHeader = styled.div`
display:flex;
align-items:flex-end;
justify-content:space-between;
@media (max-width:${Constants.LG}px) {
  flex-wrap:wrap-reverse;
  justify-content:center;
  
 
}
`;
const SubContentTitle = styled.div`
margin-right:20px;
${TextLink}

`;
const SubContentImage = styled.img`
max-width:50%;
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
 max-width:100px;
}


height:auto;
@media (max-width:${Constants.LG}px) {
 max-width:50vw;
 margin-bottom:20px;
}
`;
const SubContentText = styled.div`
font-size:16px;
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
 max-width:310px;
}

`;

const TitleStyle=styled.div`
color:${Constants.TITLE_COLOR};
${Title1}
padding-bottom:30px;
`;


export default () => {
  const [content, setContent] = useState('');

  const page = useContext(InnerPageContext);

  useEffect(() => {
    if (page.main_content_html) {
      Api.getHtmlContent(page.main_content_html).then(({ content }) => {
        setContent(content);
      }).catch((err) => {});
    } else {
      setContent(page.main_content_html);
    }
  }, []);


  return (
    <Container>
      <PageHolder>
        <TitleStyle  >
          {page.title}
        </TitleStyle>
        {page.main_image && (
        <MainImageSM
          src={`${ASSETS_SERVER}/images/${page.main_image}`}
        />
        )}
        <ContentHolder>

          <MainContent className="content_list" dangerouslySetInnerHTML={{ __html: content }} />
          <LeftContent>
            {page.main_image && (
            <MainImage
              src={`${ASSETS_SERVER}/images/${page.main_image}`}
            />
            )}
            <SubContent>
              <SubContentHeader>
                <SubContentTitle dangerouslySetInnerHTML={{ __html: page.sub_content_title }} />
                {page.sub_image && <SubContentImage src={`${ASSETS_SERVER}/images/${page.sub_image}`} />}
              </SubContentHeader>
              <SubContentText dangerouslySetInnerHTML={{ __html: page.sub_content }} />
            </SubContent>

           
          </LeftContent>
        </ContentHolder>
      </PageHolder>
    </Container>
  );
};
