export default {
  WHITE: '#FFFFFF',
  LG: 1240,
  SM: 720,
  XS: 380,
  BLACK: '#000000',
  TOP_HEIGHT: 550,
  TITLE_COLOR: '#222222',
  TEXT_COLOR: '#222222',
  HYPER_LINK_COLOR: '#316BBE',
  GREEN_2: '#00A69C',
  GREEN_3: '#27AC68',
  GREEN_4: '#2BB573',
  GREY_1: '#39383A',
  GREY_2: '#4E4F51',
  GREY_3: '#6D6E71',
  GREY_4: '#D0D2D3',
  GREY_5: '#F3F3F3',

  GREY_7: '#6D6E71',
  LATOSB: 'Lato Semibold',
  VERDANA: 'frutiger',
  BRUSHSC: 'Brush Script MT',


};

const Title1 = `
font-size:35px;
font-family:Lato Semibold;
`;

const Title2 = `
font-size:25px;
font-family:frutiger;
font-weight:bold;
`;

const TextBody = `
font-size:16px;
font-family:frutiger;

`;

const TextCaption = `
font-size:14px;
font-family:frutiger;

`;

const TextAlert = `
font-size:12px;
font-family:frutiger;

`;

const TextBTN = `
font-size:14px;
font-family:frutiger;

`;

const TextLink = `
font-size:16px;
font-family:frutiger;

`;

export {
  Title1, Title2, TextBody, TextCaption, TextAlert, TextBTN, TextLink,
};
