import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ASSETS_SERVER } from '../../lib/Api';
import {
  MoreButtonHolder, MoreButton, Container, TitleText, GradientButton,
} from '../Common';
import Constants, { Title2, TextBody } from '../Constants';

const BoxSection = styled.div`
    padding-bottom:40px;
    padding-top:40px;
    margin:auto;
  max-width:${Constants.LG}px;
  padding-right:10px;
  padding-left:10px;

`;
const Title = styled.div`
font-size:40px;
color:${Constants.TEXT_COLOR};
font-weight:bold;
margin-bottom:40px;
`;


const Boxes = styled.div`
display:flex;
justify-content:space-around;
flex-wrap:wrap;
min-height:300px;

`;

const BoxDiv = styled.div`
background-color:#ffffff;
border-radius:8px;
 
max-width:${346}px;
height:450px;
padding:10px;
display:flex;
flex-direction:column;
justify-content:space-between;
margin-top:10px;
margin-bottom:10px;

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) 
{
  flex:none;
}

`;


const Image = styled.img`
max-width:100%;
height:auto;
`;

const ImageHolder = styled.div``;

const BoxTitle = styled.div`
color:${Constants.TEXT_COLOR};
font-size:25px;
font-weight:bold;
text-align:center;
${Title2}
 
 

`;

const BoxText = styled.div`
color:${Constants.TEXT_COLOR};
text-align:center;
margin-bottom:10px;
${TextBody}

`;


const BoxButton = styled(GradientButton)`




`;


const ButtonHolder = styled.div`
width:100%;
display:flex;
justify-content:center;
align-items:center;
`;

const Box = ({ box }) => (
  <BoxDiv>
    <ImageHolder>
      <Image src={`${ASSETS_SERVER}/images/${box.image}`} />
    </ImageHolder>
    <BoxTitle dangerouslySetInnerHTML={{ __html: box.title }} />
    <BoxText dangerouslySetInnerHTML={{ __html: box.text }} />
    <ButtonHolder>
      <BoxButton title={box.link_text} to={`${box.link_name}`}>{box.link_text}</BoxButton>
    </ButtonHolder>

  </BoxDiv>
);


export default ({ section, level2 }) => {
  const [tempBoxes, setTempBoxes] = useState(section.boxes.slice(0, level2 ? section.boxes.length : Math.min(3, section.boxes.length)));

  const hasMore = section.boxes.length > 3;

  const doMore = () => {
    setTempBoxes(section.boxes);
  };

  const doLess = () => {
    setTempBoxes(section.boxes.slice(0, Math.min(3, section.boxes.length)));
  };


  return (

    <BoxSection>
      <TitleText dangerouslySetInnerHTML={{__html:section.title}}></TitleText>
      <Boxes>
        {tempBoxes.map((box) => <Box key={box.name} box={box} />)}
      </Boxes>
      {!level2 && (
      <MoreButtonHolder>
        {hasMore && tempBoxes.length <= 3 && <MoreButton color="#929292" bg="#F3F3F3" border="#BEBEBE" onClick={doMore}>More</MoreButton> }
        {hasMore && tempBoxes.length > 3 && <MoreButton color="#929292" bg="#F3F3F3" border="#BEBEBE" onClick={doLess}>Less</MoreButton> }
      </MoreButtonHolder>
      )}
    </BoxSection>

  );
};
