import React from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  if (!props.to || props.to == 'undefined') {
    return <div {...props} />;
  }

  if (/^(http)/i.test(props.to)) {
    return <a target="_blank" {...props} href={props.to} />;
  }
  if (/^(#)/.test(props.to)) {
    return <a {...props} href={props.to} />;
  }
  let path = props.to;
  if (!path) {
    return <Link {...props} />;
  }

  if (''.charAt(0) !== '/') {
    path = `/${path}`;
    const newProps={...props};
    newProps.to = path;
    return <Link {...newProps} />;
  }
 
};
