import React, { useContext } from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import Constants, { TextBTN, Title1, TextAlert, TextBody } from './Constants';
import { PageContext } from './App';
import Anchor from './Anchor';


const MoreButtonHolder = styled.div`
margin-top:20px !important;
display:flex;
margin-top:0px;
justify-content:center;
align-items:center;
`;

const Container = styled.div`
background-color:${(props) => (props.background ? props.background : Constants.WHITE)};
${(props) => (props.gradient ? `background-image:${props.gradient};` : '')}
width:100%;
;
`;

const PageContainer = styled.div`
width:auto;
`;

const ContentHolder = styled.div`
 
max-width:${() => Constants.LG}px;
margin:auto;
flex-wrap:wrap;
`;


const GreenBarStub = styled.div`
background-image:${(props) => props.gradient};
height:30px;
 
`;

const GreenBar = ({ children }) => {
  const content = useContext(PageContext);
  return <GreenBarStub gradient={content.colors.gradient_2} />;
};


const TitleText = styled.div`
 ${Title1}
color:${Constants.TITLE_COLOR};
text-align:center;
margin-bottom:20px;

`;


const TitleStyle1 = styled.div`
font-size:16px;
color:${Constants.TEXT_COLOR};
margin-bottom:40px;
text-align:center;
 


`;

const HomeButton = styled.div`

padding-top:10px;
text-decoration:none;
background-color:${(props) => (props.selected ? '#01685C' : '#01685C')};
color: #ffffff;
margin-left:2px;
margin-right:2px;
max-width:230px;
padding-left:10px;
padding-right:10px;
cursor: pointer;
padding:10px;
transition:opacity .5s ease;
text-align:center;
 ${TextAlert}
:hover
{
    opacity:.8;
}
@media (max-width:${Constants.SM}px) {
     
    max-width:50%;
    margin-left:0px;
margin-right:0px;
  
}

`; 


const PageHolder = styled.div`
padding-top:50px;
padding-bottom:20px;
margin:auto;
max-width:${Constants.LG}px;
padding-right:10px;
  padding-left:10px;
@media (max-width:${Constants.SM}px) {
 
  
}
`;


const PlainButton = styled.button`
 box-shadow: 0px 0px 0px transparent;
border: 0px solid transparent;
cursor: pointer;
text-shadow: 0px 0px 0px transparent;`;

const GhostButton = styled(PlainButton)`
min-width:250px;
background-color:${Constants.WHITE};
text-decoration:none;
padding:10px;
padding-left:20px;
padding-right:20px;
color:${Constants.HYPER_LINK_COLOR};
border-style:solid;
border-width:1px;
border-radius:8px;
border-color:#6D6E71;
${TextBTN}


`;

const MoreButton = styled(GhostButton)`
 
@media (max-width:${Constants.SM}px) {
 

    min-width:200px;
  
}

`;
//--background-image: linear-gradient(to right, ${Constants.GREEN_2},${Constants.GREEN_4});
//background-image: ${(props) => props.gradient};
const GradientButtonStub = styled(Anchor)`

 background-color:${Constants.HYPER_LINK_COLOR};
 text-align:center;
padding:16px;
border-radius:8px;
flex:1;
 ${TextBody}
 
 
 
text-decoration:none;
color:${Constants.WHITE};
transition:background-color .5s ease;
:hover{
  background-color:  #00396C;
    color:#eeeeee;
}
`;

const GradientButton = (props) => {
  const content = useContext(PageContext);
  return <GradientButtonStub {...props} gradient={content.colors.gradient_1} />;
};


const VideoLink = styled(PlainButton)`
padding-top:10px;
text-decoration:none;
background-color:${(props) => (props.selected ? '#01685C' : '#444444')};
color:${(props) => (props.selected ? Constants.WHITE : Constants.WHITE)};
margin-left:2px;
margin-right:2px;
min-width:230px;
padding-left:10px;
padding-right:10px;
cursor: pointer;
padding:10px;
transition:opacity .5s ease;
text-align:center;
 ${TextAlert}
:hover
{
    opacity:.8;
}
@media (max-width:${Constants.SM}px) {
    width:100%;
    margin-left:0px;
margin-right:0px;
  
}

`;


export {
  ContentHolder,
  GhostButton,
  TitleText,
  GradientButton,
  PlainButton,
  MoreButton,
  MoreButtonHolder,
  VideoLink,
  Container, PageContainer, GreenBar, TitleStyle1, HomeButton, PageHolder,
};
